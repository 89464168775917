//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EveryBusinessModel',
  data () {
    return {
      navs: [
        this.$t('homepage-business-model-box1-tab'),
        this.$t('homepage-business-model-box2-tab'),
        this.$t('homepage-business-model-box3-tab'),
        this.$t('homepage-business-model-box4-tab'),
        this.$t('homepage-business-model-box5-tab'),
        this.$t('homepage-business-model-box6-tab'),
        this.$t('homepage-business-model-box7-tab'),
        this.$t('homepage-business-model-box8-tab'),
        this.$t('homepage-business-model-box9-tab'),
        this.$t('homepage-business-model-box10-tab')
      ],
      rightContent: [
        {
          businessImage: require('@/assets/img/new-home/business/business1.webp'),
          tips: this.$t('homepage-business-model-box1-tab'),
          title: this.$t('homepage-business-model-box1-title'),
          description: this.$t('homepage-business-model-box1-desc'),
          isShowButton: true
        },
        {
          businessImage: require('@/assets/img/new-home/business/business2.webp'),
          tips: this.$t('homepage-business-model-box2-tab'),
          title: this.$t('homepage-business-model-box2-title'),
          description: this.$t('homepage-business-model-box2-desc'),
          isShowButton: true,
          logos: [
            require('@/assets/img/new-home/business/business2-1.svg'),
            require('@/assets/img/new-home/business/business2-2.svg'),
            require('@/assets/img/new-home/business/business2-3.svg'),
            require('@/assets/img/new-home/business/business2-4.svg'),
            require('@/assets/img/new-home/business/business2-5.svg')
          ]
        },
        {
          businessImage: require('@/assets/img/new-home/business/business3.webp'),
          tips: this.$t('homepage-business-model-box3-tab'),
          title: this.$t('homepage-business-model-box3-title'),
          description: this.$t('homepage-business-model-box3-desc'),
          isShowButton: true,
          logos: [
            require('@/assets/img/new-home/business/business3-1.svg'),
            require('@/assets/img/new-home/business/business3-2.svg'),
            require('@/assets/img/new-home/business/business3-3.svg'),
            require('@/assets/img/new-home/business/business3-4.svg')
          ]
        },
        {
          businessImage: require('@/assets/img/new-home/business/business4.webp'),
          tips: this.$t('homepage-business-model-box4-tab'),
          title: this.$t('homepage-business-model-box4-title'),
          description: this.$t('homepage-business-model-box4-desc'),
          isShowButton: true
        },
        {
          businessImage: require('@/assets/img/new-home/business/business5.webp'),
          tips: this.$t('homepage-business-model-box5-tab'),
          title: this.$t('homepage-business-model-box5-title'),
          description: this.$t('homepage-business-model-box5-desc'),
          isShowButton: true,
          logos: [
            require('@/assets/img/new-home/business/business5-1.svg'),
            require('@/assets/img/new-home/business/business5-2.svg'),
            require('@/assets/img/new-home/business/business5-3.svg')
          ]
        },
        {
          businessImage: require('@/assets/img/new-home/business/business6.webp'),
          tips: this.$t('homepage-business-model-box6-tab'),
          title: this.$t('homepage-business-model-box6-title'),
          description: this.$t('homepage-business-model-box6-desc'),
          isShowButton: true
        },
        {
          businessImage: require('@/assets/img/new-home/business/business7.webp'),
          tips: this.$t('homepage-business-model-box7-tab'),
          title: this.$t('homepage-business-model-box7-title'),
          description: this.$t('homepage-business-model-box7-desc'),
          isShowButton: true,
          logos: [
            require('@/assets/img/new-home/business/business7-1.svg'),
            require('@/assets/img/new-home/business/business7-2.svg'),
            require('@/assets/img/new-home/business/business7-3.svg'),
            require('@/assets/img/new-home/business/business7-4.svg')
          ]
        },
        {
          businessImage: require('@/assets/img/new-home/business/business8.webp'),
          tips: this.$t('homepage-business-model-box8-tab'),
          title: this.$t('homepage-business-model-box8-title'),
          description: this.$t('homepage-business-model-box8-desc'),
          isShowButton: true,
          logos: [
            require('@/assets/img/new-home/business/business8-1.svg'),
            require('@/assets/img/new-home/business/business8-2.svg'),
            require('@/assets/img/new-home/business/business8-3.svg'),
            require('@/assets/img/new-home/business/business8-4.svg')
          ]
        },
        {
          businessImage: require('@/assets/img/new-home/business/business9.webp'),
          tips: this.$t('homepage-business-model-box9-tab'),
          title: this.$t('homepage-business-model-box9-title'),
          description: this.$t('homepage-business-model-box9-desc'),
          isShowButton: true
        },
        {
          businessImage: require('@/assets/img/new-home/business/business10.webp'),
          tips: this.$t('homepage-business-model-box10-tab'),
          title: this.$t('homepage-business-model-box10-title'),
          description: this.$t('homepage-business-model-box10-desc'),
          isShowButton: true
        }
      ],
      curIndex: 0
    }
  },
  methods: {
    navChange (index) {
      this.curIndex = index
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        const element = entry.target
        element.classList.add('animation-pull-up-active')
      } else {
        const element = entry.target
        element.classList.remove('animation-pull-up-active')
      }
    }
  }
}
