//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TailoredSolutions',
  data () {
    return {
      contents: [
        {
          title: this.$t('homepage-latin-america'), img: require('@/assets/img/new-home/solutions1.webp')
        },
        {
          title: this.$t('homepage-africa-middle-east'), img: require('@/assets/img/new-home/solutions2.webp')
        },
        {
          title: this.$t('homepage-asia-pacific'), img: require('@/assets/img/new-home/solutions3.webp')
        },
        {
          title: this.$t('homepage-europe-north-america'), img: require('@/assets/img/new-home/solutions4.webp')
        }
      ]
    }
  }
}
