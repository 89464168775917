//
//
//
//
//
//
//
//
//

export default {
  name: 'LearnMoreButton',
  props: {
    section: {
      type: String,
      default: null
    }
  },
  methods: {
    visitSection (e, elementId) {
      if (document.getElementById(elementId)) {
        e.preventDefault()
        setTimeout(() => {
          return window.scrollTo({
            top: document.querySelector('#'.concat(elementId)).offsetTop,
            behavior: 'smooth'
          })
        }, 250)
      }
    }
  }
}

